import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const MinMaxInputRef = ({filterName, placeholder, value, title, prefix, onChange, disabled, cssClass = ''}, ref) => {
  const [inputValue, setInputValue] = useState(value);
  const ariaLabel = `${prefix}-${filterName}-input`;
  const changedInput = (ev) => {
    const newValue = ev.target.value;
    setInputValue(newValue);
    onChange(prefix, newValue, ev);
  };
  useImperativeHandle(ref, () => ({
    getInputValue: () => inputValue
  }));

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <input type="number"
      name={ariaLabel}
      aria-label={ariaLabel}
      className={cssClass}
      placeholder={placeholder}
      value={inputValue}
      onChange={ (ev) => changedInput(ev) }
      onBlur={ (ev) => changedInput(ev) }
      title={title}
      disabled={disabled}
    />
  );
};


const MinMaxInput = forwardRef(MinMaxInputRef);

MinMaxInput.propTypes = {
  filterName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  classSize: PropTypes.string,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool
};
export {MinMaxInput};
