import React from 'react';
import {MinMaxInput} from '../FilterSections/MinMaxInput';
import {
  SRP_LITERALS,
  useMinMaxLabels, useMinMaxTypers, defaultGetMinMax, useDelayedTyping
} from '../../RefineSearch/FilterHelpers/useSRPFilters';

import PropTypes from 'prop-types';
const {MIN, MAX} = SRP_LITERALS;

const FilterYear = ({handleDataChange, isThreeColumnLayout, messages, isLoading, min = '', max = ''}) => {
  const filterNameKey = 'year';
  const {placeholderMin, placeholderMax, titleMin, titleMax, messageTo} = useMinMaxLabels(isThreeColumnLayout, messages, filterNameKey);
  const {onType, onTypeEnd, disabledMinMax, minRef, maxRef} = useMinMaxTypers(handleDataChange, filterNameKey, defaultGetMinMax);
  const {onStopTyping} = useDelayedTyping(onType, onTypeEnd);
  return (
    <div className="search-filter year">
      <div className="search-filter-group">
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MIN}
          placeholder={placeholderMin}
          value={min}
          title={titleMin}
          cssClass={'year-min'}
          onChange={onStopTyping}
          ref={minRef}
          disabled={disabledMinMax === 'max' || isLoading}
        />
        <span>{messageTo}</span>
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MAX}
          placeholder={placeholderMax}
          value={max}
          title={titleMax}
          cssClass={'year-max'}
          onChange={onStopTyping}
          ref={maxRef}
          disabled={disabledMinMax === 'min' || isLoading}
        />
      </div>
    </div>
  );
};

FilterYear.propTypes = {
  isThreeColumnLayout: PropTypes.bool,
  messages: PropTypes.object,
  max: PropTypes.string,
  min: PropTypes.string,
  cookies: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChange: PropTypes.func,
  isLoading: PropTypes.bool
};

export default FilterYear;
