import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {
  SRP_LITERALS,
  useMinMaxLabels, useDelayedTyping, useMinMaxTypers, defaultGetMinMax
} from '../../RefineSearch/FilterHelpers/useSRPFilters';
import {PortalConfigContext} from '../../../../../config/portal';
import classnames from 'classnames';
import {getLength as getUomAbbr} from '../../../../../utils/uomHelper';
import {yieldToMain} from '../../../../../utils';
import {useDispatch} from 'react-redux';
import {UOM_COOKIE} from '../../../../../constants/cookies';
import {setCookie} from '../../../../../utils/cookies';
import {MinMaxInput} from '../FilterSections/MinMaxInput';

const {MIN, MAX} = SRP_LITERALS;

const SupportedUoms = ({customUom, handleDataChange, cookies, min, max}) => {
 const context = useContext(PortalConfigContext);
 const dispatch = useDispatch();
 const {supportedUoms} = context;
  const [customUomAbbr, setCustomUomAbbr] = useState(() => {
    return getUomAbbr(customUom?.length, context);
  });
  const supportedUomsList = Object.keys(supportedUoms);
  if (!(supportedUomsList.length > 1)) {
    return null;
  }
  const handleUomChange = async (uom) => {
    const inOneDay = new Date();
    inOneDay.setDate(inOneDay.getDate() + 1);

    const data = {
      value: 1,
      expires: inOneDay,
      path: '/'
    };
    setCustomUomAbbr(uom);

    await yieldToMain();
    dispatch({type: 'GET_DATA_LOADING'});
    setCookie(cookies, UOM_COOKIE, uom, data);
    await yieldToMain();
    handleDataChange({min, max});
  };
  return (<div className="uom-wrapper">
    {supportedUomsList.map((uom, index) => (
      <div className={classnames('filled-block', { active: uom.includes(customUomAbbr) })}
        onClick={() => {
          handleUomChange(uom);
        }}
        key={index}
      >
        {uom}
      </div>
    ))}
  </div>);
};

SupportedUoms.propTypes = {
  show: PropTypes.bool,
  supportedUoms: PropTypes.array,
  customUom: PropTypes.object,
  cookies: PropTypes.object,
  handleDataChange: PropTypes.func,
  max: PropTypes.string,
  min: PropTypes.string,
};

const FilterLength = ({handleDataChange, customUom, isThreeColumnLayout, messages, cookies, isLoading, min = '', max = ''}) => {
  const filterNameKey = 'length';
  const {placeholderMin, placeholderMax, titleMin, titleMax, messageTo} = useMinMaxLabels(isThreeColumnLayout, messages, filterNameKey);

  const {onType, onTypeEnd, disabledMinMax, minRef, maxRef} = useMinMaxTypers(handleDataChange, filterNameKey, defaultGetMinMax);
  const {onStopTyping} = useDelayedTyping(onType, onTypeEnd);

  return (
    <div className="search-filter length">
      <div className="search-filter-group">
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MIN}
          placeholder={placeholderMin}
          value={min}
          title={titleMin}
          cssClass={'small length-min'}
          onChange={onStopTyping}
          ref={minRef}
          disabled={disabledMinMax === 'max' || isLoading}
        />
        <span>{messageTo}</span>
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MAX}
          placeholder={placeholderMax}
          value={max}
          title={titleMax}
          cssClass={'small length-max'}
          onChange={onStopTyping}
          ref={maxRef}
          disabled={disabledMinMax === 'min' || isLoading}
        />
        <SupportedUoms customUom={customUom} cookies={cookies} handleDataChange={handleDataChange} min={min} max={max}/>
      </div>
    </div>
  );
};

FilterLength.propTypes = {
  isThreeColumnLayout: PropTypes.bool,
  messages: PropTypes.object,
  max: PropTypes.string,
  min: PropTypes.string,
  cookies: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChange: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default FilterLength;
