import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { Cookies } from 'react-cookie';
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import SearchAlertsContainer from '../SearchAlerts/components/Boats';
import MainContent from '../../MainContent';
import PillBox from '../../../../components/PillBox';
import {
  FilterMake,
  FilterYear,
  FilterClass,
  FilterModels,
  FilterLength,
  FilterLocation,
  FilterFuelType,
  FilterForSaleBy,
  FilterHullMaterial,
  FilterEngineDetails,
} from '../Filter';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleHeader
} from '../../../../components/Collapsible';
import {
  getPortalDefaultCountry,
  portalHasCountryFilter
} from '../../../../utils/locationHelper';
import {
  getRegions,
  getSubdivisions,
  getCities
} from '../../../../utils/multiFacetHelper';
import {
  setGenericEvent,
  setSearchTracking
} from '../../../../store/actions/dataLayer';
import {
  getDefaultParams,
  getActiveParams,
} from '../../../../utils/urlHelpers/boats';
import { getBoatsConstants } from '../../../../constants/boats';
import { PortalConfigContext } from '../../../../config/portal';
import { getCurrentLocale } from '../../../../utils/language';

import { scrollToTop } from '../../../../utils/scrollTo';
import { getRadiuses } from '../../utils/radiusSearch';

import './styles.css';
import {yieldToMain} from '../../../../utils';
import {filtersLoading} from '../Filter/utils';
import { getMessages } from '../../../../tppServices/translations/messages';

/* Loadable Facets */
const FilterCondition = loadable(() => import('../Filter/FilterCondition'));
const FilterPrice = loadable(() => import('../Filter/FilterPrice'));

const shouldCollapsibleBeOpen = (facetsConfig, facetName) => {
  const facetConfig = facetsConfig.filter(
    (facet) => facet.name === facetName
  )[0];
  return get(facetConfig, 'initialState.open', false);
};

class RefineSearch extends PureComponent {
  state = {
    initialState: this.props.initialState || 'open',
    data: getDefaultParams(get(this.props, 'match.params', {})),
    wasRegionChangedByUser: false
  };

  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
  }

  componentDidUpdate(prevProps) {
    const prevUrl = get(prevProps.match, 'url', '');
    const currentUrl = get(this.props.match, 'url', '');
    if (currentUrl !== prevUrl) {
      this.setCurrentState();
    }
  }

  setCurrentState() {
    const params = this.getCurrentState();
    this.setState({
      data: params
    });
  }

  getCurrentState() {
    const theDefault = getDefaultParams(get(this.props, 'match.params', {}));
    return theDefault;
  }

  getLocationFilterConfig(facetConfig, tracking) {
    const locationFilterConfig = {};

    locationFilterConfig.shouldCollapsibleBeOpen = {
      radius: shouldCollapsibleBeOpen(facetConfig, 'radius')
        ? 'open'
        : 'closed',
      country: shouldCollapsibleBeOpen(facetConfig, 'country')
        ? 'open'
        : 'closed',
      city: shouldCollapsibleBeOpen(facetConfig, 'city') ? 'open' : 'closed',
      region: shouldCollapsibleBeOpen(facetConfig, 'region')
        ? 'open'
        : 'closed',
      subdivision: shouldCollapsibleBeOpen(facetConfig, 'subdivision')
        ? 'open'
        : 'closed'
    };
    locationFilterConfig.tracking = tracking;

    return locationFilterConfig;
  }

  getLocationFilterData(filtersData, facets) {
    const radiuses = getRadiuses();
    const selectedCountry = portalHasCountryFilter()
      ? filtersData.country
      : getPortalDefaultCountry();
    const selectedRadius = radiuses.find((r) => r.value === filtersData.radius);
    const locationFilterData = {
      radiusesData: {
        radiuses: radiuses,
        selected: selectedRadius
      },
      countriesData: {
        countries: get(facets, 'country', []),
        selected: selectedCountry
      },
      citiesData: {
        cities: getCities(facets, selectedCountry),
        selected: filtersData.city
      },
      regionsData: {
        regions: sortBy(getRegions(facets, selectedCountry), 'value'),
        selected: filtersData.region
      },
      subdivisionsData: {
        subdivisions: getSubdivisions(facets, selectedCountry),
        selected: filtersData.subdivision
      }
    };

    return locationFilterData;
  }

  async updateStateOnChange(type, value) {
    await yieldToMain();
    let wasRegionChangedByUser = this.state.wasRegionChangedByUser;
    let location = {};

    if (type === 'region') {
      location.subdivision = '';
      wasRegionChangedByUser = value !== 'all';
      location.city = [];
    }

    if (type === 'subdivision') {
      location.city = [];
    }

    if (type === 'country') {
      location.radius = '0';
      location.region = '';
      location.subdivision = '';
      location.city = [];
    }

    if (type === 'radius') {
      location.country = '';
      location.region = '';
      location.subdivision = '';
      location.city = [];
    }

    const newState = {
      data: {
        ...this.state.data,
        [type]: value,
        ...location
      },
      wasRegionChangedByUser
    };

    this.setState(newState);
    return newState;
  }

  async onChangedFilters(type, value) {
    const newState = await this.updateStateOnChange(type, value);
    this.props.onDataChange(newState.data);
  }

  async handleDataChange(type, value) {
    const newState = await this.updateStateOnChange(type, value);
    this.debounceOnDataChange(newState.data);
  }

  async handleDataClear() {
    await yieldToMain();
    this.props.setGenericEvent('site search', 'guided search', 'clear filters');
    const newState = {
      data: {
        ...getDefaultParams({}),
        owner: undefined,
        sort: undefined
      }
    };
    this.setState(newState);

    this.props.onDataChange(newState.data);
    scrollToTop();
  }

  debounceOnDataChange = debounce((data) => {
    this.props.onDataChange(data);
  });

  facetAddedTracker(facet) {
    this.props.setSearchTracking(facet, 'single', 'guided search');
  }

  facetRemovedTracker(facet) {
    this.props.setGenericEvent('site search', 'guided search', facet);
  }

  render() {
    const params = this.getCurrentState();
    const currentParams = getActiveParams(params);
    const tracking = {
      facetAdded: this.facetAddedTracker.bind(this),
      facetRemoved: this.facetRemovedTracker.bind(this)
    };
    const messages = this.translationMessages;
    const {
      intl: { formatMessage: t },
      mobileParams,
      customUom
    } = this.props;
    const { data } = this.state;
    const {
      length,
      year,
      price,
      fuelType,
      hullMaterial,
      multiFacetedBoatTypeClass,
      makeModel,
    } = data;
    const facets = get(this.props, 'facets', []);
    const seoMakeInfo = get(this.props, 'seoMakeInfo');
    const makes = get(facets, 'make', []);
    const models = get(facets, 'makeModel', []);
    const classFacets = get(facets, 'class', []);
    const fuelTypeFacets = get(facets, 'fuelType', []);
    const hullMaterialFacets = get(facets, 'hullMaterial', []);
    const facetConfig = this.context.pages.searchResults.facets;
    const isBranded = this.props.mode === MainContent.MODES.branded;
    const currentLanguage = getCurrentLocale();
    const searchAlerts =
      this.props.position === 'desktop' &&
      get(this.context.supports, 'searchAlerts') &&
      !get(
        this.context.languages[currentLanguage],
        'disableSearchAlerts',
        false
      );
    const radiusSearch = get(this.context.supports, 'radiusSearch', {
      enabled: false,
      brandingV2: false
    });
    const anyLoading = filtersLoading(this.props.isWorking, this.props.componentWorking);
    return (
      <>
        {searchAlerts ? (
          <SearchAlertsContainer
            cookies={this.props.cookies}
            match={this.props.match}
            intl={this.props.intl}
            eventCategory="above search filter"
            active={mobileParams || currentParams}
            makeModel={models}
            seoMakeInfo={seoMakeInfo}
          />
        ) : null}
        <PillBox
          customUom={customUom}
          active={mobileParams || currentParams}
          params={getDefaultParams({})}
          handleDataChange={(type, value) =>
            this.handleDataChange(type, value )
          }
          onClear={() => this.handleDataClear()}
          makeModel={models}
          seoMakeInfo={seoMakeInfo}
          searchType={getBoatsConstants().SEARCH_TYPES.boats}
        />
        <FilterLocation
          position={this.props.position}
          params={params}
          tracking={tracking}
          radiusSearch={radiusSearch}
          filtersConfig={this.getLocationFilterConfig(facetConfig, tracking)}
          filtersData={this.getLocationFilterData(data, facets)}
          handleDataChange={this.handleDataChange.bind(this)}
        />
        {facetConfig.filter((facet) => facet.name === 'condition').length >
        0 ? (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'condition')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.condition)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterCondition
                position={this.props.position}
                condition={data.condition}
                handleDataChange={this.handleDataChange.bind(this)}
                tracking={tracking}
                id={`filter-condition-${this.props.position}`}
                params={params}
              />
            </Collapsible>
          </CollapsibleContent>
        ) : null}
        {facetConfig.filter((facet) => facet.name === 'length').length > 0 ? (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'length') ? 'open' : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.length)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterLength
                customUom={customUom}
                cookies={this.props.cookies}
                min={length.min}
                max={length.max}
                isThreeColumnLayout={this.props.isThreeColumnLayout}
                id="filter-length"
                messages={messages}
                handleDataChange={this.onChangedFilters.bind(this)}
                isLoading={anyLoading}
              />
            </Collapsible>
          </CollapsibleContent>
        ) : null}
        <CollapsibleContent initialState="open">
          <CollapsibleHeader priority={0}>
            {t(messages.price)}
          </CollapsibleHeader>
          <Collapsible extraClass="collapsible-price">
            <FilterPrice
              min={price.min}
              max={price.max}
              handleDataChange={this.onChangedFilters.bind(this)}
              tracking={tracking}
              isThreeColumnLayout={this.props.isThreeColumnLayout}
              messages={messages}
              userCurrency={this.props.userCurrency}
              isLoading={anyLoading}
            />
          </Collapsible>
        </CollapsibleContent>
        <CollapsibleContent initialState="open">
          <CollapsibleHeader priority={0}>{t(messages.year)}</CollapsibleHeader>
          <Collapsible>
            <FilterYear
              min={year.min}
              max={year.max}
              handleDataChange={this.onChangedFilters.bind(this)}
              isThreeColumnLayout={this.props.isThreeColumnLayout}
              messages={messages}
              isLoading={anyLoading}
            />
          </Collapsible>
        </CollapsibleContent>
        <CollapsibleContent initialState="closed">
          <CollapsibleHeader priority={0}>{t(messages.type)}</CollapsibleHeader>
          <Collapsible>
            <FilterClass
              facets={classFacets}
              handleDataChange={this.handleDataChange.bind(this)}
              multiFacetedBoatTypeClass={multiFacetedBoatTypeClass}
              params={params}
              position={this.props.position}
              tracking={tracking}
            />
          </Collapsible>
        </CollapsibleContent>
        <CollapsibleContent initialState="closed">
          <CollapsibleHeader priority={0}>{t(messages.make)}</CollapsibleHeader>
          <Collapsible>
            <FilterMake
              makes={makes}
              makeModels={makeModel}
              position={this.props.position}
              loading={includes(this.props.componentWorking, 'models')}
              handleDataChange={this.handleDataChange.bind(this)}
              params={params}
              tracking={tracking}
            />
          </Collapsible>
        </CollapsibleContent>
        <FilterModels
          makes={makes}
          makeModels={makeModel}
          models={models}
          position={this.props.position}
          loading={includes(this.props.componentWorking, 'models')}
          handleDataChange={this.handleDataChange.bind(this)}
          params={params}
          tracking={tracking}
        />

        <FilterEngineDetails
          position={this.props.position}
          data={data}
          facets={facets}
          params={params}
          handleDataChange={this.handleDataChange.bind(this)}
          tracking={tracking}
        />

        {facetConfig.filter((facet) => facet.name === 'fuelType').length > 0 ? (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'fuelType')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.fuelType)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterFuelType
                handleDataChange={this.handleDataChange.bind(this)}
                fuelTypes={fuelTypeFacets}
                params={params}
                selectedFuelTypes={fuelType}
                tracking={tracking}
                position={this.props.position}
              />
            </Collapsible>
          </CollapsibleContent>
        ) : null}
        {facetConfig.filter((facet) => facet.name === 'hullMaterial').length >
        0 ? (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'hullMaterial')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.hullMaterial)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterHullMaterial
                handleDataChange={this.handleDataChange.bind(this)}
                hullTypes={hullMaterialFacets}
                params={params}
                selectedHullTypes={hullMaterial}
                position={this.props.position}
              />
            </Collapsible>
          </CollapsibleContent>
        ) : null}
        {facetConfig.filter((facet) => facet.name === 'forSale').length > 0 &&
        !isBranded ? (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'forSale')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.forSale)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterForSaleBy
                position={this.props.position}
                selectedForSale={data.forSale}
                handleDataChange={this.handleDataChange.bind(this)}
                params={params}
                tracking={tracking}
              />
            </Collapsible>
          </CollapsibleContent>
        ) : null}
      </>
    );
  }
}

RefineSearch.propTypes = {
  match: PropTypes.object,
  componentWorking: PropTypes.arrayOf(PropTypes.string),
  facets: PropTypes.shape({
    class: PropTypes.arrayOf(
      PropTypes.shape({
        /** Class slug */
        value: PropTypes.string,
        count: PropTypes.number,
        /** Formatted class name */
        name: PropTypes.string,
        /** Formatted class type name */
        heading: PropTypes.string
      })
    ),
    country: PropTypes.arrayOf(
      PropTypes.shape({
        /** Country initials */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    ),
    fuelType: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        count: PropTypes.number,
        /** Fuel type slug */
        urlId: PropTypes.string,
        /** Formatted fuel type name */
        name: PropTypes.string,
        heading: PropTypes.string
      })
    ),
    hullMaterial: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        count: PropTypes.number,
        /** Hull material slug */
        urlId: PropTypes.string,
        /** Formatted hull material name */
        name: PropTypes.string,
        heading: PropTypes.string
      })
    ),
    make: PropTypes.arrayOf(
      PropTypes.shape({
        /** Formatted make name */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    ),
    makeModel: PropTypes.arrayOf(
      PropTypes.shape({
        /** Formatted make name */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        /** Models list for the make */
        model: PropTypes.arrayOf(
          PropTypes.shape({
            /** Model formatted name */
            value: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired
          })
        )
      })
    ),
    subdivision: PropTypes.arrayOf(
      PropTypes.shape({
        /** State initials */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    )
  }),
  initialState: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  mode: PropTypes.any,
  onDataChange: PropTypes.func.isRequired,
  position: PropTypes.string,
  setGenericEvent: PropTypes.func.isRequired,
  setSearchTracking: PropTypes.func.isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  mobileParams: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  isThreeColumnLayout: PropTypes.bool,
  userCurrency: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  isWorking: PropTypes.bool
};

const mapStateToProps = (state) => {
  let sortBy = get(state, 'app.sortBy');
  return { sortBy };
};

RefineSearch.contextType = PortalConfigContext;
export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      setGenericEvent,
      setSearchTracking
    },
    dispatch
  )
)(injectIntl(RefineSearch));

